<template>
    <div :style="`width:${props.width}`"></div>
</template>



<script setup>
    import { ref, onUpdated, onBeforeUpdate, useSlots } from 'vue';

    const props = defineProps({
        width: Number,
        collapse: Boolean,
    });

</script>

<script>
    export default {
        name: 'OPanel'
    }
</script>